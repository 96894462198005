body {
  margin: 0;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee; /* #fafafa; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content-container {
  margin-top: 40px;
  padding: 40px 15px;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading {
  margin-bottom: 15px;
}

.form-signin .form-signin-subheading {
  margin-bottom: 15px;
  display: block;
}

.form-signin .username {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin .password {
  margin-bottom: 25px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.device-list .device:hover, .device-list .device.selected {
  background-color: lightcyan;
  cursor: pointer;
}

.device-list .device-name {
  font-weight: bold;
}

.device-list .device-image {
  height: 40px;
}

.orbit-options .row, .api-options .row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.orbit-options .api-key {
  word-wrap: break-word;
}

.api-table {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0.0, 0.0, 0.0, 0.05);
}

.trigger-panel .row, .action-panel .row {
  padding: 5px 0px;
}